require("./flowbite.min.js");

$(document).ready(function () {
    /** Carousel testimonials */
    const swiper = new Swiper(".testimonial-section.swiper", {
        // Optional parameters
        direction: "horizontal",
        loop: true,

        // If we need pagination
        pagination: {
            el: ".swiper-pagination",
        },

        // Navigation arrows
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
});
